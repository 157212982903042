import { qBase } from "./q-base";
import { bindable } from "aurelia-framework";

import * as Fhir from "../../classes/FhirModules/Fhir";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { NitTools } from "../../classes/NursitTools";

import { ConfigService } from "../../services/ConfigService";
import { QVabutton } from "./q-vabutton";
import {Questionnaire} from "../../classes/FhirModules/Fhir";
const moment = require("moment");

export class QChoice extends qBase {
    __responseItem = undefined;
    
    options: any[] = [];
    responseItemAnswer: string = "";
    previousValue: string | string[];
    previousResponseItem = undefined;
    showVAArea: boolean = false;
    hasVAValue: boolean = false;
    targetsPossible: boolean = false;
    targetsVisible: boolean;
    showtargetbuttons: boolean;
    showVAButton: boolean;
    hasTargets : boolean = false;

    @bindable response;
    @bindable previousresponse;
    @bindable item;
    @bindable changed: Function;
    @bindable readonly;

    get responseItem() {
        return this.__responseItem;
    }

    set responseItem(value) {
        this.__responseItem = value;
        this.checkVA();

        this.showVAArea = this.hasVAValue;
    }

    get isReadonly() {
        if (this.item && this.item.readOnly) return true;
        // return from form
        return this.readonly;
    }

    getPreviousValue() {
        try {
            this.previousResponseItem = undefined;
            this.previousValue = undefined;

            if (this.previousresponse && this.item) {
                this.previousResponseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.previousresponse, this.item.linkId);

                if (this.previousResponseItem) {
                    this.previousValue = Fhir.QuestionnaireResponse.GetResponseItemValue(this.previousResponseItem);
                }
            } else {
                // console.warn("[Q-Choice] - Kein Value für previousResponse. Item:", this.item, " PR:", this.previousresponse)
            }
        } catch (e) {
            console.warn(e.message);
            return undefined;
        }
    }

    previousresponseChanged() {
        if (this.previousresponse) {
            this.getPreviousValue();
        }
    }

    targetChanged(target) {
        this.options.forEach(o => o.targetValue = false);
        let option = this.options.find(o => o.value === target.targetValue);
        if (option) {
            option.targetValue = true;
            option.inTime = moment(target.targetDate).isAfter(new Date());
        }

        this.hasTargets = !!target.targetValue;
    }

    vaValueChanged(sender) {
        this.checkVA();
    }

    async responseChanged() {
        this.createOptions();
        this.selectRightValue();
        this.getPreviousValue();

        await QuestionnaireService.GetQuestionnaireIds();

        if (!this.responseItem && (this.response && this.item)) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
        }

        this.checkVA();
    }

    hasExtension = false;
    checkTargetVisibility() {
        if (this.item && this.item.extension) {
            const toggleExtenstion = this.item.extension.find(o => o.url.endsWith('/questionnaire-toggle-target-display'));
            let _showTargets = false;
            if (toggleExtenstion && typeof toggleExtenstion.valueBoolean === "boolean") {
                _showTargets = toggleExtenstion.valueBoolean;
                this.hasExtension = true;
            }

            // Targets may be globally disabled. So when the extension says display but config says nooo - do not display it
            if (_showTargets && ConfigService.cfg && ConfigService.cfg.features && typeof ConfigService.cfg.features.showTargets === "boolean") {
                if (!ConfigService.cfg.features.showVA)
                    _showTargets = false;
            }

            // this.__targetsVisible = _showTargets;
            this.targetsPossible = _showTargets && this.item.option && this.item.option.length > 0;
            this.showtargetbuttons = _showTargets && this.targetsPossible;
        }
    }

    checkVAButtonVisibility() {
        let _vaButton = false;
        if (this.item.extension) {
            const vaButtonExtension = this.item.extension.find(o => o.url.endsWith('/questionnaire-va-button-visible'));
            if (vaButtonExtension && typeof (vaButtonExtension.valueBoolean) === "boolean") {
                _vaButton = vaButtonExtension.valueBoolean;
            }

            // VA-Button may be globally disabled. So when the extension says display but config says nooo - do not display it
            if (_vaButton && ConfigService.cfg && ConfigService.cfg.features && typeof ConfigService.cfg.features.showVA === "boolean") {
                if (!ConfigService.cfg.features.showVA)
                    _vaButton = false;
            }
        }

        this.showVAButton = _vaButton;
    }

    itemChanged(newVal) {
        if (newVal && newVal["answerOption"])
            newVal.option = newVal["answerOption"];

        if (!newVal || !newVal.option) {
            this.options = [];
            this.responseItemAnswer = undefined;
            return;
        }

        this.createOptions();
        this.selectRightValue();
        this.getPreviousValue();

        // get the override for (not) displaying the target toggle
        this.checkTargetVisibility();
        this.checkVAButtonVisibility();

        this.checkVA();

        if (ConfigService.Debug) {
            if (!window["choices"]) window["choices"] = {};
            window["choices"][this.item.linkId] = this;
        }
    }

    onItemValueChangeNotified(item) {
        super.onItemValueChangeNotified(item);
        this.setItemValue(Fhir.QuestionnaireResponse.GetResponseItemValue(item));
    }

    getItemClass(option) {
        let className = option.value === this.responseItemAnswer ? 'active' : '';

        if (this.isReadonly) {
            className += "btn-readonly";
        }

        return className;
    }

    getOptionText(value: string): string {
        if (!this.item) return undefined;
        let option = this.options.find(o => o.value === value);
        if (option) {
            return option.hint || option.text;
        }
    }

    setItemValue(newVal, callselectRightValue: boolean = true, executeChanged: boolean = true) {
        this.getPreviousValue();
        if (this.isReadonly) return;

        if (!this.responseItem && this.item && this.response) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
            if (!this.responseItem) {
                const q = QuestionnaireService.GetQuestionnaireDirect(this.response.questionnaire);
                if (q) {
                    Questionnaire.EnsureStructuredResponse(q, this.response);

                    this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
                    if (!this.responseItem) {
                        console.warn(q.name + ' - Emergency created linkId: ' + this.item.linkId + ' in Response: ', this.response)
                        this.responseItem = {
                            linkId: this.item.linkId,
                            answer: []
                        };
                        this.response.item.push(this.responseItem);
                    }
                }
            }
        }

        if (!newVal && this.responseItem && this.item && this.item.linkId === "E2_I_132") {
            if (this.item.initialCoding && this.item.initialCoding.code) {
                newVal = this.item.initialCoding.code;
                if (ConfigService.Debug)
                    console.debug('Using Default value for ' + this.item.linkId + ': "' + newVal + '"');
            }
        }

        if (this.responseItem) {
            let txt = this.getOptionText(newVal);
            const srcOption = this.options.find(o=>o.value == newVal);

            let answerCoding = {
                valueCoding: {
                    code: newVal,
                    display: txt
                }
            };

            if (!txt || (newVal && newVal.endsWith('_nil'))) {
                answerCoding.valueCoding.display = '';
            }

            this.responseItem.answer = [answerCoding];

            if (callselectRightValue) this.selectRightValue();

            this.responseItem.answer = [answerCoding]; // just to be sure ... seems to go bollox in selectRightValue, #2774, https://nursiti.planio.de/issues/2774
            if (executeChanged && typeof this.changed === "function") {
                this.changed(this.responseItem);
            }
        } else {
            console.warn("No Response-Item assigned");
        }
    }

    selectRightValue() {
        this.responseItemAnswer = undefined;
        this.responseItem = undefined;

        if (this.response && this.item && this.item.linkId) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
            if (this.responseItem) {
                this.responseItemAnswer = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem);
                let newVal = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem);
                if (newVal == "0") newVal = undefined;
                this.setItemValue(newVal, false, false);
            }
        }
    }

    checkVA() {
        try {
            if (!this.item || !this.responseItem) {
                this.hasVAValue = false;
                this.showVAArea = false;

                return;
            }

            let _hasVAValue = false;

            if (this.responseItem && this.responseItem.extension) {
                const vaExtension = this.responseItem.extension.find(o => o.url.endsWith(QVabutton.urlPart));

                if (vaExtension && typeof (vaExtension.valueCodeableConcept) !== "undefined") {
                    const concept = vaExtension.valueCodeableConcept;
                    if (typeof concept.coding !== "undefined" && concept.coding.length > 0) {
                        _hasVAValue = true;
                    }
                }

                const targetExtension = this.responseItem.extension.find(o => o.url.endsWith('target-value'));
                this.hasTargets = typeof targetExtension !== "undefined" && !!targetExtension.valueString;
            }

            this.hasVAValue = _hasVAValue;
            // this.showVAArea = _hasVAValue;
        } catch (e) {
            console.warn(e);
        }
    }

    createOptions() {
        if (!this.item || (!this.item.option && !this.item["answerOption"]) || !this.response) return;

        this.options = [];
        if (!this.responseItem) this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, false);

        let targetItem = Fhir.Tools.GetOrCreateExtension(this.responseItem, 'target-value', false);
        let extValue = Fhir.Tools.GetExtensionValue(targetItem);

        if (this.item["answerOption"] && !this.item.option) {
            this.item.option = NitTools.Clone(this.item["answerOption"]);
        }

        (<any[]>this.item.option).filter(o => o.valueCoding && o.valueCoding.code).forEach((optionItem) => {
            let hidden = false;
            let ordValue = NaN;
            if (optionItem.extension) {
                let hiddenExtension = optionItem.extension.find(o => o.url.endsWith('questionnaire-hidden'));
                if (hiddenExtension && typeof hiddenExtension.valueBoolean === "boolean") {
                    hidden = hiddenExtension.valueBoolean === true;
                }

                let ordinalValueExtension = optionItem.extension.find(o=>o.url.endsWith('/questionnaire-ordinalValue'));
                if (ordinalValueExtension) {

                    if (typeof ordinalValueExtension.valueDecimal !== "undefined") {
                        ordValue = ordinalValueExtension.valueDecimal;
                    }
                    else if (typeof ordinalValueExtension.valueInteger !== "undefined") {
                        ordValue = ordinalValueExtension.valueInteger;
                    }
                }
            }

            let newOption = {
                value: optionItem.valueCoding.code,
                text: optionItem.valueCoding.display || "",
                hint: optionItem.valueCoding.display || "",
                ordinalValue: ordValue,
                targetValue: false,
                inTime: true
            };

            if (newOption.text.indexOf('|') > -1) {
                let arr = newOption.text.split('|');
                newOption.text = arr[0];
                newOption.hint = arr[1];
            }

            if (NitTools.IsArray(optionItem.extension) && optionItem.extension.length > 0) {
                try {
                    let extHint = optionItem.extension.find(o => o.url.endsWith('questionnaire-option-hint'));
                    if (extHint && extHint.valueString) {
                        newOption.hint = extHint.valueString;
                    }
                } catch (e) {
                    console.warn(e.message || JSON.stringify(e));
                }
            }

            if (targetItem && typeof extValue !== "undefined") {
                if (extValue === optionItem.valueCoding.code) {
                    newOption.targetValue = true;
                    let dateItem = Fhir.Tools.GetOrCreateExtension(this.responseItem, 'target-date', false);
                    let sDate = Fhir.Tools.GetExtensionValue(dateItem);
                    if (sDate) {
                        let d = moment(sDate);
                        newOption.inTime = d.isAfter(new Date());
                    }
                }
            }

            if (!hidden) this.options.push(newOption);
        });
    }
}
